<template>
    <div>
      <h3 class="title">pdf转word</h3>
      <van-form @submit="onSubmit">
        <van-field
          v-model="link"
          name="pdf链接"
          label="pdf链接"
          placeholder="pdf链接"
          :rules="[{ required: true, message: '请填写pdf链接' }]"
        />
        <van-field
          v-model="email"
          name="邮箱"
          label="邮箱"
          placeholder="邮箱"
          :rules="[{ required: true, message: '请填写邮箱' }]"
        />
        <div style="margin: 16px;">
    <van-button round block type="info" native-type="submit">提交</van-button>
  </div>
</van-form>
    </div>
</template>

<script>
import Vue from 'vue'
import { Toast } from 'vant'
Vue.use(Toast)

export default {
  name: '',
  components: {},
  props: {},
  data () {
    return {
      link: '', // pdf link
      email: '' // email
    }
  },
  computed: {},
  watch: {},
  created () {
    const { link, email } = this.$route.query
    this.link = link
    this.email = email
  },
  mounted () {},
  methods: {
    async onSubmit () {
      console.log('submit')

      const ret = await this.$http.post('/pdf/upload', 'e:\\tool.json')
      console.log(ret)
      /* const url = `./pdf/convert?link=${this.link}&email=${this.email}`
      const ret = await this.$http.get(url)
      console.log(ret)
      if (ret.data.code === 10000) {
        const token = ret.data.result.token
        Toast.success(token)
      } else {
        Toast.fails('fails')
      } */
    }
  }
}
</script>

<style scoped lang="less">
.title {
  text-align: center;
}
</style>
